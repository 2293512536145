'use client'
import React, {
  type ComponentPropsWithRef,
  type KeyboardEvent,
  useState,
  useEffect,
  useContext,
} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, A11y, Thumbs } from 'swiper/modules'
import VideoPlayer, { getYoutubeVideoIdFromUrl } from '@components/video-player'
import clsx from 'clsx'
import type { Swiper as SwiperType } from 'swiper'
import type { VideoPlayerProps } from '@components/video-player'
import { Button, Icon, Typography } from '@shc/ui'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import {
  BlockContext,
  ItemContext,
  PageContext,
  PlaceContext,
  ProviderContext,
} from '@lib/analytics'

export interface MediaVideoCarouselProps extends ComponentPropsWithRef<'div'> {
  media: VideoPlayerProps[]
  showThumbs?: boolean
}

const MediaVideoCarousel = ({ media, className, showThumbs = false }: MediaVideoCarouselProps) => {
  const { trackSnowplow } = useAnalytics()
  const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [swiper, setSwiper] = useState<SwiperType>()
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null)

  // Environment contexts
  const pageContext = useContext(PageContext)
  const placeContext = useContext(PlaceContext)
  const providerContext = useContext(ProviderContext)
  const blockContext = useContext(BlockContext)
  const itemContext = useContext(ItemContext)
  const environmentContexts: SPContext[] = [
    pageContext,
    placeContext,
    providerContext,
    blockContext,
    itemContext,
  ].filter((context) => context !== null)

  useEffect(() => {
    const onPageLoad = () => {
      setTabIndex(activeIndex)
    }

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pauseAll = () => {
    const frames = Array.from(document.getElementsByTagName('iframe'))
    for (const frame of frames) {
      frame.contentWindow?.postMessage(
        '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
        'https://www.youtube.com'
      )
    }
  }

  const setTabIndex = (activeIndex: number) => {
    const slides = document
      .getElementsByClassName('swiper-wrapper')[0]
      .getElementsByClassName('swiper-slide')

    for (let i = 0; i < slides.length; i++) {
      const iframe = slides[i].children[0].children[0].children[0]
      const captionAnchors = slides[i].children[1].getElementsByTagName('a')

      if (i === activeIndex) {
        iframe.removeAttribute('tabindex')
      } else {
        iframe.setAttribute('tabindex', '-1')
      }

      for (const anchor of captionAnchors) {
        if (i === activeIndex) {
          anchor.removeAttribute('tabindex')
        } else {
          anchor.setAttribute('tabindex', '-1')
        }
      }
    }
  }

  const selectSlide = (event: KeyboardEvent, slideIndex: number) => {
    if (swiper !== undefined && (event.key === 'Enter' || event.key === ' ')) {
      swiper.slideTo(slideIndex)
    }
  }

  return (
    <div className={className}>
      <Swiper
        onInit={(swiper) => {
          setSwiper(swiper)
        }}
        onSlideChangeTransitionStart={(swiper) => {
          setActiveIndex(swiper.activeIndex)
          setTabIndex(swiper.activeIndex)
          pauseAll()
          trackSnowplow({
            event: { name: 'component_scroll', data: {} },
            contexts: [
              ...environmentContexts,
              {
                name: 'component',
                data: {
                  component_text: 'MediaVideoCarousel',
                },
              },
            ],
          })
        }}
        loop={false}
        watchOverflow={false}
        a11y={{
          enabled: true,
          firstSlideMessage: 'This is the first slide',
          lastSlideMessage: 'This is the last slide',
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[Navigation, A11y, Thumbs]}
        navigation={{
          prevEl,
          nextEl,
        }}
        slidesPerView={1}
        spaceBetween={20}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}>
        {media?.map(({ children, ...slide }, idx: number) => {
          return (
            <SwiperSlide key={idx}>
              <VideoPlayer {...slide} />
              <div className="text-sm text-left pt-5 caption">
                {slide.title && (
                  <Typography variant="h3" className="pb-2">
                    {slide.title}
                  </Typography>
                )}
                <strong>
                  {idx + 1} of {media.length}
                  {children && ': '}
                </strong>
                {children}
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {showThumbs && (
        <div className="flex flex-row">
          {media.length > 3 && (
            <div className="hidden lg:flex flex-row items-center justify-center pr-3">
              <Button
                variant="outlined"
                color="transparent"
                size="sm"
                shape="circle"
                width="auto"
                aria-label="Previous Slide"
                ref={(node) => setPrevEl(node)}
                className="swiperPrev cursor-pointer"
                disabled={activeIndex === 0}>
                <Icon className="h-6" icon="chevron-left" />
              </Button>
            </div>
          )}
          <Swiper
            onSlideChangeTransitionStart={() => {
              trackSnowplow({
                event: { name: 'component_scroll', data: {} },
                contexts: [
                  ...environmentContexts,
                  {
                    name: 'component',
                    data: {
                      component_text: 'MediaVideoCarousel',
                    },
                  },
                ],
              })
            }}
            onSwiper={setThumbsSwiper}
            a11y={{
              enabled: true,
              firstSlideMessage: 'This is the first slide',
              lastSlideMessage: 'This is the last slide',
            }}
            keyboard={{
              enabled: true,
            }}
            modules={[Navigation, A11y, Thumbs]}
            spaceBetween={16}
            watchSlidesProgress={true}
            className="pt-6 md:pt-8 w-full h-full"
            breakpoints={{
              320: {
                slidesPerView: media.length >= 3 ? 2.5 : media.length,
              },
              640: {
                slidesPerView: media.length >= 4 ? 3.5 : 3,
              },
              1024: {
                slidesPerView: 3,
              },
            }}>
            {media?.map((slide, idx: number) => {
              let videoId = getYoutubeVideoIdFromUrl(slide.videoUrl)

              return (
                <SwiperSlide key={slide.id} className="group px-1">
                  <button
                    aria-label={`Go to slide ${idx + 1}`}
                    onKeyDown={(e: KeyboardEvent) => selectSlide(e, idx)}
                    className="focus:outline focus:outline-[3px] outline-warning focus:rounded">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={`http://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                      alt={slide.title ?? 'Related video'}
                      className={clsx(
                        'rounded group-hover:opacity-100',
                        idx === activeIndex ? 'opacity-100' : 'opacity-50'
                      )}
                    />
                  </button>
                  {slide.title && (
                    <div
                      className={clsx(
                        'hidden md:block text-sm font-semibold group-hover:underline',
                        idx === activeIndex && 'underline'
                      )}>
                      {slide.title}
                    </div>
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
          {media.length > 3 && (
            <div className="hidden lg:flex flex-row items-center justify-center pl-3">
              <Button
                variant="outlined"
                color="transparent"
                size="sm"
                shape="circle"
                width="auto"
                aria-label="Next Slide"
                ref={(node) => setNextEl(node)}
                className="swiperNext cursor-pointer"
                disabled={media.length - 1 === activeIndex}>
                <Icon className="h-6" icon="chevron-right" />
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MediaVideoCarousel
